import axios from '@/features/api/axios';

const { post } = axios;
const get = (path, params = {}) => axios.get(path, { params });

let api = {
  /**
   * 获取广告列表
   */
  getAdList: (data) => {
    return get('activity/index', data);
  },
  /**
   * 获取广告详情
   */
  getAdDetail: (data) => {
    return get('activity/info', data);
  },
  /**
   * 获取区代主页信息
   */
  getAgentIncomeInfo: (data) => {
    return get('region/income', data);
  },
  /**
   * 获取区代观看审核列表
   */
  getAgentCheckList: (data) => {
    return get('region/audit-list', data);
  },
  /**
   * 获取收货地址列表
   */
  getAddressList: (data) => {
    return get('address/lists', data);
  },

  /**
   * 获取收货地址详情
   */
  getAddressInfo: (data) => {
    return get('address/info', data);
  },

  /**
   * 设置收货地址状态
   */
  setAddress: (data) => {
    return post('address/save', data);
  },

  /**
   * 获取积分商品列表
   */
  getItgList: (data) => {
    return get('integral/goods', data);
  },
  /**
   * 获取免费实物线路图申请列表
   */
  getEmsLst: (data) => {
    return get('ems/lists', data);
  },
  /**
   * 获取免费实物线路图申请详情
   */
  getEmsDetail: (data) => {
    return get('ems/info', data);
  },

  /**
   * 提交免费实物线路图申请
   */
  saveEmsDetail: (data) => {
    return post('ems/save', data);
  },
  /**
   * 获取培训详情
   */
  getTrainInfo: (data) => {
    return get('training/info', data);
  },
  /**
   * 获取车辆详情
   */
  getCarInfo: (data) => {
    return get('car/info', data);
  },
  /**
   * 获取招聘详情
   */
  getJobInfo: (data) => {
    return get('job/info', data);
  },
  /**
   * 获取培训列表
   */
  getTrainList: (data) => {
    return get('training/index', data);
  },
  /**
   * 获取招聘列表
   */
  getJobList: (data) => {
    return get('job/list', data);
  },
  /**
   * 获取车辆列表
   */
  getCarList: (data) => {
    return get('car/index', data);
  },
  /**
   * 获取招聘城市信息
   */
  getJobCitys: (data) => {
    return get('city/city', data);
  },
  /**
   * 获取提现页面信息
   */
  getCashInfo: (data) => {
    return get('cash/money', data);
  },
  /**
   * 获取提现审核中信息
   */
  getCashIngInfo: (data) => {
    return get('distributor/check-data', data);
  },
  /**
   * 获取区代提现审核中信息
   */
  getAgentCashIngInfo: (data) => {
    return get('region/check-data', data);
  },
  /**
   * 获取首页列表
   */
  getLessonList: (data) => {
    return get('lesson', data);
  },
  /**
   * 获取token
   */
  getToken: (data) => {
    return get('wx', data);
  },
  /**
   * 获取用户信息
   */
  getUserInfo: async (data) => {
    return await get('student/member-info', data);
  },
  /**
   * 获取城市列表
   */
  getCitys: (data) => {
    return get('city', data);
  },
  /**
   * 获取地区列表
   */
  getAddressArea: (data) => {
    return get('city/address-area', data);
  },
  /**
   * 搜索城市/考场
   */
  searchCitys: (data) => {
    return get('city/search-field', data);
  },
  /**
   * 获取我的视频
   */
  getLessons: (data) => {
    return get('order/lessons', data);
  },
  /**
   * 获取我的线路图
   */
  getLines: (data) => {
    return get('lesson/diagram', data);
  },
  /**
   * 获取我的订单
   */
  getOrders: (data) => {
    return get('order', data);
  },
  /**
   * 获取线路图详情信息
   */
  getLineInfo: (data) => {
    return get('lesson/line-figure', data);
  },
  /**
   * 获取课程详情信息
   */
  getlessonInfo: (data) => {
    return get('lesson/info', data);
  },
  /**
   * 获取微信授权信息
   */
  wxConfig: (data) => {
    return get('wx/sdk', data);
  },

  /**
   * 获取评论列表
   */
  getComments: (data) => {
    return get('comment', data);
  },
  /**
   * 二维码课程列表
   */
  qrlessons: (data) => {
    return get('coach/qr-lessons', data);
  },
  /**
   * 获取教练线路图列表
   */
  getCoaLines: (data) => {
    return get('distributor', data);
  },
  /**
   * 获取下级代理列表
   */
  getAgentList: (data) => {
    return get('distributor/agent-list', data);
  },
  /**
   * 获取提现列表
   */
  getCashList: (data) => {
    return get('cash', data);
  },

  /**
   * 绑定分佣关系
   */
  extension: (data) => {
    return post('student/extension', data);
  },
  /**
   * 上传登录日志
   */
  upLoginLog: (data) => {
    return post('student/login-log', data);
  },
  /**
   * 获取公众号二维码
   */
  getOffAccCode: (data) => {
    return post('v2/wx/focus', data);
  },
  /**
   * 获取客服二维码
   */
  getGuestCode: (data) => {
    return post('comment/the-guest', data);
  },
  /**
   * 删除订单
   */
  delOrder: (data) => {
    return post('order/del', data);
  },
  /**
   * 绑定手机
   */
  bindPhone: (data) => {
    return post('student/binding-mobile', data);
  },
  /**
   * 获取验证码
   */
  getSmsCode: (data) => {
    return post('student/sms', data);
  },
  /**
   * 注销账号
   */
  delAcc: (data) => {
    return post('student/del-acc', data);
  },

  /**
   * 清理分销账户收益
   */
  delExtAccData: (data) => {
    return post('cash/del-data', data);
  },
  /**
   * 清理代理账户收益
   */
  delAgentAccData: (data) => {
    return post('region/del-data', data);
  },
  /**
   * 获取视频播放信息
   */
  getVideoInfo: (data) => {
    return post('lesson/vod', data);
  },
  /**
   * 获取支付信息
   */
  getOrderProd: (data) => {
    return post('order/prod', data);
  },
  /**
   * 提交评论
   */
  submitComment: (data) => {
    return post('comment/publish', data);
  },
  /**
   * 打赏
   */
  submitGive: (data) => {
    return post('comment/publish-prod', data);
  },
  /**
   * 记忆播放时间
   */
  submitVideoTime: (data) => {
    return post('lesson/play-record', data);
  },
  /**
   * 捆绑二维码跳转
   */
  qrcoach: (data) => {
    return post('coach/qr', data);
  },
  /**
   * 用户绑定二维码
   */
  bindQr: (data) => {
    return post('coach/binding-qr', data);
  },
  /**
   * 我的二维码列表
   */
  myQrList: (data) => {
    return post('coach/qr-list', data);
  },
  /**
   * 教练端获取首页数据
   */
  getCoaLessonList: (data) => {
    return get('distributor/dst-index', data);
  },
  /**
   * 获取分享课程信息
   */
  getShareLesInfo: (data) => {
    return post('lesson/share', data);
  },
  /**
   * 申请免费观看
   */
  freeApply: (data) => {
    return post('distributor/apply', data);
  },
  /**
   * 获取添加考场的课程列表
   */
  addLessonList: (data) => {
    return post('distributor/manage-lessons', data);
  },
  /**
   * 添加考场
   */
  addField: (data) => {
    return post('distributor/add-lesson', data);
  },
  /**
   * 移除考场
   */
  removeField: (data) => {
    return post('distributor/del-lesson', data);
  },
  /**
   * 获取教练视频
   */
  getCoaLessons: (data) => {
    return post('distributor/lessons', data);
  },
  /**
   * 注册教练
   */
  registerCoa: (data) => {
    return post('student/reg-dst', data);
  },
  /**
   * 获取收入信息
   */
  getIncomeInfo: (data) => {
    return post('distributor/income', data);
  },
  /**
   * 获取收益列表
   */
  getIncomeList: (data) => {
    return post('distributor/revenue-list', data);
  },
  /**
   * 立即提现
   */
  cashNow: (data) => {
    return post('cash/cash', data);
  },
  /**
   * 获取教练名片信息
   */
  getCoaInfo: (data) => {
    return post('student/line', data);
  },
  /**
   * 修改教练名片信息
   */
  editCoaInfo: (data) => {
    return post('student/line-change', data);
  },
  /**
   * 更改职位状态
   */
  setJobStatus: (data) => {
    return post('job/status', data);
  },
  /**
   * 更改车辆状态
   */
  setCarStatus: (data) => {
    return post('car/status', data);
  },
  /**
   * 更改培训状态
   */
  setTrainStatus: (data) => {
    return post('training/status', data);
  },
  /**
   * 新建/编辑招聘
   */
  jobEdit: (data) => {
    return post('job/publish', data);
  },
  /**
   * 新建/编辑车辆
   */
  carEdit: (data) => {
    return post('car/publish', data);
  },
  /**
   * 新建/编辑培训
   */
  trainEdit: (data) => {
    return post('training/publish', data);
  },
  /**
   * 上传图片
   */
  imgUpload: (data) => {
    return post('file/upload', data);
  },
  /**
   * 消息通知开关是否显示
   */
  pushShowStatus: (data) => {
    return post('distributor/open-push', data);
  },
  /**
   * 消息推送设置
   */
  setPushStatus: (data) => {
    return post('distributor/focus-push', data);
  },
  /**
   * 获取积分记录
   */
  itgRecord: (data) => {
    return post('integral/integral-record', data);
  },
  /**
   * 获取兑换记录/已兑换商品
   */
  itgOrder: (data) => {
    return post('integral/orderlist', data);
  },
  /**
   * 获取积分订单详情
   */
  getItgOrderDetail: (data) => {
    return post('integral/order-details', data);
  },
  /**
   * 积分订单退款
   */
  backItg: (data) => {
    return post('integral/refund', data);
  },
  /**
   * 获取商品详情
   */
  getItgDetail: (data) => {
    return post('integral/goods-details', data);
  },
  /**
   * 兑换积分商品
   */
  subItgGood: (data) => {
    return post('integral/ex-change-product', data);
  },

  /**
   * 获取配送列表
   */
  getDphList: (data) => {
    return post('integral/marki', data);
  },
  /**
   * 获取配送详情
   */
  getDphDetail: (data) => {
    return post('integral/order-details', data);
  },
  /**
   * 修改配送状态
   */
  setDphStatus: (data) => {
    return post('integral/etc-marki', data);
  },
  /**
   * 投诉列表
   */
  getCpnList: (data) => {
    return post('comment/comp-list', data);
  },
  /**
   * 投诉详情
   */
  getCpnDetail: (data) => {
    return post('comment/comp-details', data);
  },
  /**
   * 获取部分功能显示状态
   */
  getShowStatus: (data) => {
    return post('integral/show-tab', data);
  },
  /**
   * 回复评论
   */
  comReply: (data) => {
    return post('comment/reply', data);
  },
  /**
   * 区代审核观看
   */
  setAgentCheck: (data) => {
    return post('region/audit', data);
  },
  /**
   * 获取区代收益列表
   */
  getAgentIncomeList: (data) => {
    return post('region/revenue-list', data);
  },
  /**
   * 是否显示团购分销
   */
  showCarGroup: (data) => {
    return post('student/car-ads', data);
  },
  /**
   * 团购分销详情列表
   */
  getCarGroup: (data) => {
    return post('student/car-ads-list', data);
  },
  /**
   * 加入团购
   */
  joinCarGroup: (data) => {
    return post('student/add-car-ads', data);
  },
  /**
   * 参加活动
   */
  joinAd: (data) => {
    return post('activity/inside', data);
  },
  /**
   * 切换播放地址
   */
  switchPlayer: (data) => {
    return post('lesson/replace-play', data);
  },
  /**
   * 打卡签到信息
   */
  punchInfo: (data) => {
    return get('coach/punch', data);
  },
  /**
   * 账单推送
   */
  orderDstext: (data) => {
    return get('order/dst-ext', data);
  },
  /**
   * 地推绑定设置
   */
  spreadSetting: (data) => {
    return post('coach/spread-setting', data);
  },
  /**
   * 绑定地推人员
   */
  bindingSpread: (data) => {
    return post('coach/binding-spread', data);
  },
  /**
   * 地推码列表
   */
  spreadList: (data) => {
    return get('coach/spread-list', data);
  },
  /**
   * 地推码列表
   */
  loginIntegral: (data) => {
    return get('integral/login-integral', data);
  },
  /**
   * 考场统计数据
   */
  fieldIncome: (data) => {
    return get('region/field-income', data);
  },
  /**
   * 同步线路图信息
   */
  lineSyncName: (data) => {
    return get('student/line-sync-name', data);
  },
  /**
   * 通用设置
   */
  getSetting: (data) => {
    return get('student/tabs', data);
  },
  /**
   * 招聘人才列表
   */
  getTalenLst: (data) => {
    return get('recruit/index', data);
  },
  /**
   * 视频建议
   */
  viodeAdd: (data) => {
    return post('suggest/add', data);
  },
  /**
   * 招聘详情
   */
  recDetail: (data) => {
    return get('recruit/detail', data);
  },

  /**
   * 预约安装时间
   */
  installTime: (data) => {
    return post('integral/install-time', data);
  },
  updateLicense: (data) => {
    return post('member-license/update-license', data);
  },
  licenseInfo: (data) => {
    return get('member-license/info', data);
  },

  // 积分解锁权益
  integralCode: (data) => {
    return post('integral/order-code', data);
  },
  // 审核超时事项
  examMatters: (data) => {
    return get('exam/matter', data);
  },

  // 产品列表
  proList: (data) => {
    return get('products/index', data);
  },
  //产品详情
  proDetail: (data) => {
    return get('products/detail', data);
  },

  //产品下单
  proPay: (data) => {
    return post('products/prod', data);
  },
  //填写资料时获取产品及订单信息
  getProInfo: (data) => {
    return get('products/info', data);
  },
  //提交资料
  saveProInfo: (data) => {
    return post('products/save-info', data);
  },
  getProOrders: (data) => {
    return get('products/orders', data);
  },
  delProOrders: (data) => {
    return post('products/del-order', data);
  },
};

export default api;
